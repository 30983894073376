import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import Link from "lib/Link";

@withI18next(["common"])
@inject("appStore", "readerStore")
@observer
class FeatureBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    let { t, appStore, readerStore } = this.props;
    let { featureBlock } = appStore;
    let { session } = readerStore;
    return (
      <>
        <section className="col-4-4-4 feature_block">
          <div className="container">
            {featureBlock !== null &&
              featureBlock.feature &&
              featureBlock.feature.map((columnData, key) => {
                let titleName =
                  columnData.langcode === ""
                    ? columnData.title
                    : t(columnData.langcode);
                return (
                  <div className="col">
                    <div className="featured_data">
                      <h2>{titleName}</h2>
                      <ul>
                        {columnData.data &&
                          columnData.data.map((row, key) => {
                            return (
                              <li>
                                <Link href={row.url}>{row.name}</Link>
                              </li>
                            );
                          })}
                        {columnData.mappingData &&
                          columnData.mappingData.map((row, key) => {
                            let out = [];
                            if (
                              row.data &&
                              row.condition ===
                                session.groupIDVal +
                                  "-" +
                                  session.useUnitGroupSN
                            ) {
                              row.data.map((row2, key) => {
                                out.push(
                                  <li>
                                    <Link href={row2.url}>{row2.name}</Link>
                                  </li>
                                );
                              });
                            }
                            return out;
                          })}
                      </ul>
                      {columnData.moreUrl !== "" && (
                        <Link href={columnData.moreUrl} className="more">
                          {t("jumperrwd.featureblock.more", {
                            moreName: titleName,
                          })}
                        </Link>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </section>
      </>
    );
  }
}

FeatureBlock.defaultProps = {};

FeatureBlock.propTypes = {
  t: PropTypes.func,
};
export default FeatureBlock;
