import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";

import { page } from "components/page";
import SearchBlock from "components/search/SearchBlock";
import FeatureBlock from "components/common/FeatureBlock";

@withI18next(["common"])
@page
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { t, i18n, search, appStore } = this.props;
    let { globalConfig } = appStore;
    let tabbar = globalConfig["jumper.common.tabbar.default"];
    if (tabbar === "collections") {
      tabbar = "hold";
    } else if (tabbar === "fullsearch") {
      tabbar = "integration";
    }
    let searchBlockType = search.searchBlockType || tabbar;
    let { language } = i18n;
    let tImageSrc = "",
      defaultTSrc = "/file/images/logo.png";
    if (language === "zh") {
      tImageSrc = "/file/images/logo.png";
    } else if (language === "en") {
      tImageSrc = "/file/images/logo_en.png";
    }
    if (tImageSrc === "") {
      tImageSrc = defaultTSrc;
    }
    return (
      <Layout {...this.props}>
        <div className="main mp" id="center">
          <a class="accesskey" href="#aC" id="aC" accesskey="C" title="主要內容區">:::</a>
          <section className="mp_logo">
            <img src={tImageSrc} alt={this.props.t("jumper.sys.name")+" Logo"} />
          </section>
          <SearchBlock searchType={searchBlockType}  />
          <FeatureBlock />
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
